export const faqElements = [
  [
    {
      question: "Godziny pracy gabinetów",
      answer: [
        "1. Katowice ul. Sokolska 65 , 7 dni w tygodniu 12:00-20:00",
        "2. Warszawa ul. Modlińska 199a wtorek 15:00-19:00, środa i czwartek 13:30 - 16:30, piątek 15:00-19:00",
        "3. Rzeszów ul. Hetmańska 9, wtorek i czwartek 16:00 - 19:30",
        "4. Łódź ul. Bratysławska 2a - czwartek 15:00 - 20:00",
        "5. Bydgoszcz ul. A. Mickiewicza 3 - czwartek 15:00 - 20:00",
        "6. Poznań ul. Sieroca 2 - czwartek 15:00 - 20:00",
        "7. Radom ul. Wyścigowa 12 - środa 12:00 - 20:00",
        "8. Lublin ul. Okopowa 5 - wtorek i czwartek 17:00 - 22:00",
        "9. Wrocław ul. Powstańców Śląskich 163 - czwartek 15:00 - 20:00",
        "10. Kraków ul. Chmieleniec 2a - czwartek 16:00 - 20:00",
        "11. Kielce ul. Paderewskiego 4, gabinet 233 raz w miesiącu - poniedziałek 12:00 - 18:00",
        "12. Biłgoraj ul. 3-go Maja 87a - raz w miesiącu 18;00 - 20:00",
        "13. Tarnów ul. Mościckiego 14, - sobota 12:00 - 15:00",
        "14. Częstochowa aleja Wolności 10 - wtorek 17:00 - 20:00",
        "15. Stalowa Wola ul. Popiełuszki 2 - raz w miesiącu wybrany dzień 16:00 - 20:00",
        "16. Gdańsk ul. Rakoczego 11/U6 - piątek 12:00 - 15:00",
        "17. Opole ul. Niemodlińska 19/26 - Pawilon Karo - wtorek 19:00 - 21:00 i piątek 15:00 - 20:00",
        "18. Szczecin ul. Cisowa 45 - środa 15:30 - 20:00",
        "19. Koszalin ul. Młyńska 59/2 - piątek 16:30 - 20:00",
        "20. Zielona Góra ul. Wrocławska 17 - sobota 12:00 - 16:00",
        "21. Konin ul. Spółdzielców 73 - Dobrostan centrum terapii naturalnych - sobota 12:00-15:00",
        "22. Białystok ul. młynowa 30 - środa 16:00-20:00",
      ],
    },
  ],
];
