import * as React from "react";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import { Divider } from "../../shared/divider";
import {
  AboutUsSectionContainer,
  AboutUsSectionWrapper,
  AboutUsTextContainer,
  Background,
  IllustrationWrapper,
  Quotes,
  SubHeading,
} from "./about-us-section.components";
import IllustrationMobile from "./illustration-mobile.svg";
import Illustration from "./illustration.svg";

const AboutUsSection: React.FunctionComponent<
  Record<string, React.RefObject<HTMLDivElement>>
> = React.forwardRef<
  HTMLDivElement,
  Record<string, React.RefObject<HTMLDivElement>>
>((_, ref) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <AboutUsSectionContainer ref={ref}>
      {!isSmallDevice && <Background />}
      <AboutUsSectionWrapper>
        <IllustrationWrapper>
          {isSmallDevice ? <IllustrationMobile /> : <Illustration />}
        </IllustrationWrapper>
        <AboutUsTextContainer>
          <h1>O nas</h1>
          <Divider marginBlock={32} />
          <p>
            Poradnia KonopnyMed to centrum medyczne składające się z zespołu
            lekarzy, farmaceutów oraz specjalistów w zakresie terapii medyczną
            marihuaną, która dedykowana jest pacjentom zmagającymi się z
            najróżniejszymi schorzeniami. Wieloletnie doświadczenie w terapii
            medyczną marihuaną, współpraca z najlepszymi lekarzami oraz
            indywidualne podejście, są podstawą zadowolenia naszych Pacjentów.
          </p>
          <br />

          <p>
            KonopnyMed Sp. Z o.o. | ul. 3-go Maja 87a, 23-400 Biłgoraj
            <br />
            Biuro ul. Misjonarzy Oblatów 8, 40-129 Katowice
            <br />
            nr KRS 0000997754 | Sąd Rejonowy Lublin Wschód w Lublinie
            <br />
            NIP 918-218-20-68 | REGON 523421863
            <br />
            <b>
              Podmiot wykonujący działalność leczniczą pod numerem księgi
              000000256607
            </b>
            <br />
            Nr konta PKO KonopnyMed: <b>54 1020 1068 0000 1702 0399 0793</b>
          </p>

          <SubHeading>Adresy placówek</SubHeading>
          <p>1. Katowice ul. Sokolska 65, parter, lokal nr 5</p>
          <p>2. Warszawa ul. Modlińska 199a obok Apteki Sativa</p>
          <p>3. Rzeszów ul. Hetmańska 9, 3 piętro, gabinet 353</p>
          <p>4. Łódź ul. Bratysławska 2a obok Apteki Medest 24h</p>
          <p>5. Bydgoszcz ul. A. Mickiewicza 3</p>
          <p>6. Poznań ul. Sieroca 2</p>
          <p>7. Radom ul. Wyścigowa 12 - NZOZ Ustronie</p>
          <p>8. Lublin ul. Okopowa 5, parter, gabinet nr 46</p>
          <p>9. Wrocław ul. Powstańców Śląskich 163/1c</p>
          <p>10. Kraków ul. Chmieleniec 2a</p>
          <p>11. Kielce ul. Paderewskiego 4, gabinet 233</p>
          <p>12. Biłgoraj ul. 3-go Maja 87a - Gabinety Konsylium</p>
          <p>13. Stalowa Wola ul. Popiełuszki 2</p>
          <p>14. Tarnów ul.  Mościckiego 14, 4 piętro gabinet nr 1</p>
          <p>15. Częstochowa aleja Wolności 10</p>
          <p>16. Gdańsk ul. Rakoczego 11/U6</p>
          <p>17. Opole ul. Niemodlińska 19/26 - Pawilon Karo</p>
          <p>18. Szczecin ul. Cisowa 45</p>
          <p>19. Koszalin ul. Młyńska 59/2</p>
          <p>20. Zielona Góra ul. Wrocławska 17</p>
          <p>
            21. Konin ul. Spółdzielców 73 - Dobrostan centrum terapii
            naturalnych
          </p>
          <p>22. Białystok ul. młynowa 30</p>
          <br />
          {!isSmallDevice && <Quotes />}
        </AboutUsTextContainer>
      </AboutUsSectionWrapper>
    </AboutUsSectionContainer>
  );
});

export default AboutUsSection;
